import css1 from '../img/portImages/GitBook.png';
import css2 from '../img/portImages/cym.png';
import react1 from '../img/portImages/amazon-clone.png';
import react2 from '../img/portImages/cryptotrackr.png'
import react3 from '../img/portImages/portfolio.png'
import img2 from '../img/portImages/FoodTruck.png';
import img3 from '../img/portImages/AnywhereFitness.png';



const portfolios = [
    {
        id: 1,
        category: 'HTML/CSS',
        image: css1,
        link1: 'https://github.com/build-week-github-user-breakdown/Marketing-page',
        link2: 'https://new-gitbook.netlify.app/',
        title: 'Gitbook',
        text: 'This app utilizes the Github API so you can keep tabs on the coding activity of your favorite developers. Created a landing page using vanilla HTML/CSS while following figma files provided by UX designer.'
    },
    {
        id: 2,
        category: 'React.Js',
        image: react2,
        link1: 'https://github.com/GeorgeDav9/dark-mode',
        link2: 'https://cryptotrackr.vercel.app/',
        title: 'Crypto Trackr',
        text: 'Follow the value of the most popular crypto currencies available! Created with HTML/CSS and React.Js. '
    },
    {
        id: 3,
        category: 'Node.Js',
        image: img3,
        link1: 'https://github.com/Build-Week-Anywhere-Fitness-04/back-end/tree/master',
        link2: 'https://lucid-morse-c45b1a.netlify.app/index.html',
        title: 'Anywhere Fitness',
        text: 'Anywhere Fitness connects instructors to clients in an awkwardness-avoiding, barrier-smashing platform that intelligently connects you to the right instructor, in the right place. Built entire backend using Node.Js, SQLite3 and integrated Stripe to take payments from students.'
    },
    {
        id: 4,
        category: 'React.Js',
        image: img2,
        link1: 'https://github.com/Build-Week-FoodTruck-TrackR-1/FrontEnd',
        link2: 'https://food-truck-track-r.vercel.app/register',
        title: 'Food Truck Trackr',
        text: 'App similar to Yelp for food tracking and reviewing foodtrucks. Built front end user interface using React.Js and HTML/CSS. included full Stripe and payment integration for vendors and customers.'
    },
    {
        id: 5,
       category: 'React.Js',
       image: react3,
       link1: 'https://github.com/GeorgeDav9/portfolio-website',
       link2: 'https://georgedavila.com/',
       title: 'Responsive Portfolio',
       text: 'Displays information about me and showcases projects I worked on and created. Built using React.Js, Styled-Components, Material-UI and much more!'
    },
    {
        id: 6,
        category: 'React.Js',
        image: react1,
        link1: 'https://github.com/GeorgeDav9/Amazon-Clone',
        link2: 'https://clone-89ffe.web.app/',
        title: 'Amazon-Clone',
        text: 'Single page web app with full e-commerce functionality that is made to be an exact clone of Amazon. Created with HTML/CSS, React.Js, Node.Js, Stripe and Firebase.'
    },
    {
        id: 7,
        category: 'HTML/CSS',
        image: css2,
    //     link1: 'https://www.google.com',
        link2: 'https://www.cityyouthmatrix.com/',
        title: 'City Youth Matrix',
        text: 'Website for local non-profit. CYM focuses on helping local youth in my community with extracurricular activies and events. Created using HTML/CSS and JavaScript.'
    }
]

export default portfolios;