import React from 'react';
import styled from 'styled-components';
import {InnerLayout} from '../styles/Layouts';
import Title from '../Components/Title';
import SmallTitle from '../Components/SmallTitle';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import SchoolIcon from '@material-ui/icons/School';
import ResumeItem from '../Components/ResumeItem';

function Resume() {
    const briefcase = <BusinessCenterIcon />
    const school = <SchoolIcon />
    return (
        <ResumeStyled>
            <Title title={'Resume'} span={'resume'} />
            <InnerLayout>
                <div className="small-title">
                    <SmallTitle icon={briefcase} title={'Working Experience'} />
                </div>
                <div className="resume-content">
                    <ResumeItem 
                        year={'August 2020 - Present'} 
                        title={'Front End Developer'}
                        subTitle={'City Youth Matrix'}
                        text={"Write website code using the programming languages JavaScript, HTML and CSS. Incorporate applications, images and video clips into the website. Create tools that enhance the UI experience. Ensure the website is accessible on many platforms, such as laptops and smartphones. "} 
                    />
                    <ResumeItem 
                        year={'May 2020 - July 2020'} 
                        title={'Front End Developer'}
                        subTitle={'Merch Dropper'}
                        text={'Collaborated remotely with a team of 5 Web developers and a UX/UI designer to work on an already existing website to improve functionality and user interface, This was a 2 month long position through Lambda School that would give us experience in an Agile work enviroment  . '} 
                    />
                    <ResumeItem 
                        year={' August 2016 - April 2020'} 
                        title={'Customer Service Representative'}
                        subTitle={'Verizon Wireless'}
                        text={'Assisted customers with solving problems, offered solutions, and customer service. Offered technical support to customer. Implemented system for creating Gmail, iCloud, and Microsoft accounts  for customers switching to mobile operating systems who had no experience in doing so. '} 
                    />
                </div>
                <div className="small-title u-small-title-margin">
                    <SmallTitle icon={school} title={'Educational Qualifications'} />
                </div>
                <div className="resume-content ">
                    <ResumeItem 
                        year={'2019 - 2020'} 
                        title={'Full Stack Web Development/Computer Science'}
                        subTitle={'Lambda School'}
                        text={'In October 2019 I joined a bootcamp called Lambda School. Lambda School is a 9+ month coding bootcamp that provides students with an immersive hands-on, project oriented curriculum with a focus on Full Stack Web Development and Computer Science . '} 
                    />
                    <ResumeItem 
                        year={'2017 - 2019'} 
                        title={'Computer Science Credits'}
                        subTitle={'Towson University'}
                        text={'In August 2017 I was accepted into Towson University in Towson, MD. I Completed a year and a half worth of course work with the goal of earning a Bachelor Degree in Computer Science. Due to personal reasons I was not able to continue my degree plans.'} 
                    />
                    <ResumeItem 
                        year={'2014 - 2017'} 
                        title={"Computer Science Degree"}
                        subTitle={'Frederick Community College'}
                        text={'After graduating High School in May 2014, I attended my local community college and completed enough course work to earn an Associates degree in Computer Science. During this time I knew that I wanted to further my education and attend a 4 year school.'} 
                    />
                </div>
            </InnerLayout>
        </ResumeStyled>    
    )
}

const ResumeStyled = styled.section`
    .small-title{
        padding-bottom: 3rem;
    }
    .u-small-title-margin{
        margin-top: 4rem;
    }

    .resume-content{
        border-left: 2px solid var(--border-color);
    }
`;
export default Resume
