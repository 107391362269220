import React from 'react'
import styled from 'styled-components';
import resume from '../img/profile.png';
import PrimaryButton from './PrimaryButton';

function ImageSection() {
    return (
        <ImageSectionStyled>
            <div className="left-content">
                <img src={resume} alt=""/>
            </div>
            <div className="right-content">
                <h4>I am <span>George Davila</span></h4>
                <p className="paragraph">
                Hello,  I'm a full stack web developer with a background in customer service. 
                Currently seeking new remote opportunities. Front end web development is my #1 talent and passion. 
                I don't finish an app or website until it fulfills my high standards. 
                I always make sure any project I work on has a seamless transition from any screen size. I'm also strong with HTML, CSS, JavaScript, React, Redux, Node.Js, SQL, Python, and deployment with Zeit, Firebase or AWS. I served as a sales associate for almost 4 years which developed my customer service and communication skills to a massive degree.
                 To learn more about me and my work, visit my portfolio to see all of my deployed projects. Thank you for reading!
                    
                </p>
                <div className="about-info">
                    <div className="info-title">
                        <p>Full Name</p>
                        <p>Age</p>
                        <p>Nationality </p>
                        <p>Languages </p>
                        <p>Location</p>
                        <p>Service</p>
                    </div>
                    <div className="info">
                        <p>: George Davila-Galeas</p>
                        <p>: 25</p>
                        <p>: American </p>
                        <p>: Spanish and English </p>
                        <p>: Frederick, MD</p>
                        <p>: Full Stack Web Development</p>
                    </div>
                </div>
            </div>
        </ImageSectionStyled>
    )
}


const ImageSectionStyled = styled.div`
    margin-top: 5rem;
    display: flex;
    @media screen and (max-width:1000px){
        flex-direction: column;
        .left-content{
            margin-bottom: 2rem;
        }
    }
    .left-content{
        width: 100%;
        img{
            width: 95%;
            object-fit: cover;
        }
    }
    .right-content{
        width: 100%;
        h4{
            font-size: 2rem;
            color: var(--white-color);
            span{
                font-size: 2rem;
            }
        }
        .paragraph{
            padding: 1rem 0;
        }
        .about-info{
            display: flex;
            padding-bottom: 1.4rem;
            .info-title{
                padding-right: 3rem;
                p{
                    font-weight: 600;
                }
            }
            .info-title, .info{
                p{
                    padding: .3rem 0;
                }
            }
        }
    }
`;
export default ImageSection;
